import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { StatusCode } from 'src/app/utils/common/status-code';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { SmartCAAuth } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-auth';
import { SmartCACert } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-cert';
import { SignBox } from 'src/app/utils/models/aas-models/signbox/sign-box';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ErrorService } from 'src/app/utils/services/error/error.service';

declare var PDFSign: any;
declare var default_img: any;
@Component({
  selector: 'app-smart-ca',
  templateUrl: './smart-ca.component.html',
  styleUrls: ['./smart-ca.component.scss']
})
export class SmartCaComponent implements OnInit {
  @ViewChild('filebase') filebase!: ElementRef;

  @Input()
  get contractData() { return this.contract; }
  set contractData(value) { this.contract = new ContractObject(value); }
  private contract: ContractObject = new ContractObject();
  @Input()
  set contractId(value) { this._contractId = value; };
  get contractId() { return this._contractId; };
  _contractId = "";

  @Output() savedSignature: EventEmitter<any> = new EventEmitter();
  @Output() expiredSessionSmartCA: EventEmitter<any> = new EventEmitter();
  @Output() isWaitingConfirmSmartCA: EventEmitter<any> = new EventEmitter();
  @Output() getCAInfo: EventEmitter<any> = new EventEmitter();

  localize = localize;

  currentStepSign = 0;
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = AasValidateMessage.SIGNATURE_CONFIG;
  inputFileImageLabel: string = this.translate.instant('SmartCa_selectImage');
  img_prefix = "data:image/png;base64,";
  imgBase64: string = this.img_prefix + default_img;
  imgFile: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  // keyTest: string = Config.KEY_PLUGIN_CA;
  //mark: phương thức ký
  signForm: string = '';
  hinhThucHienThi = [
    { value: 0, name: "ContractCensorship_ts_10" },
    { value: 1, name: "ContractCensorship_ts_11" },
    { value: 2, name: "ContractCensorship_ts_12" },
    { value: 3, name: "ContractCensorship_ts_13" },
    { value: 4, name: "ContractCensorship_ts_14" },
    { value: 5, name: "ContractCensorship_ts_15" }
  ]

  oAuthCert: SmartCAAuth = null;
  smartCert: SmartCACert = new SmartCACert();
  configSmartCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();
  confirmSmartCA = false;
  showLayoutConfirmSmartCA = false;
  counter$: Observable<any>;
  count = 0;
  timeoutSmartCa = false;
  reqSignSmartCA = null;

  msgError = this.translate.instant('SmartCa_ts_1');

  signTypeSmartCA = "HASH"

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private signatureService: SignatureService,
    private myAlert: AlertControl,
    private authService: AuthService,
    private controlAccount: ControlAccount,
    private errorService: ErrorService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeFormSign();
  }

  //Khoi tao form ky
  makeFormSign() {
    this.formSign = this.fb.group({
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl(5),
      base64Image: new FormControl(""),
      fontSize: new FormControl("13", [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(true),
      signDate: new FormControl(true),
      imageFile: new FormControl(""),
    });
  }

  //Reset du lieu
  resetSmartCA() {
    this.oAuthCert = null;
    this.smartCert = null;
    this.configSmartCA = new InfoSignatureConfigObject();
    this.confirmSmartCA = false;
    this.showLayoutConfirmSmartCA = false;
    this.isWaitingConfirmSmartCA.emit(false);
    this.timeoutSmartCa = false;
    this.expiredSessionSmartCA.emit(false);
  }

  //Bat dau phiem ky smartCA
  startSignSmartCA() {
    this.resetSmartCA();

    PDFSign.setStyleSelectedSignBox(this.formSign.value.visibleType);
    if (this.imgBase64 !== '') {
      PDFSign.setImage(this.imgBase64.split(',')[1]);
    } else {
      PDFSign.setImageDefault();
    }
    PDFSign.removeAllSignBox();

    this.getSessionSmartCA();

    this.currentStepSign = 1;//Danh dau buoc tiep theo
  }

  /**
   * Hien thi goi ky signbox
   * @param signFrame 
   */
  showTemplateSignBox(signFrame = []) {
    console.log("kySmartCA ", signFrame);
    if (signFrame.length > 0) {
      signFrame.forEach((item: SignBox) => {
        PDFSign.addSignBox(item, this.contract.allowAddSignBox);
      })
      setTimeout(() => {
        PDFSign.reloadTable(null, this.contract.allowAddSignBox);
      }, 100)
    }
  }

  //Ky lai
  resign() {
    console.log("called resign smartCA");
    this.startSignSmartCA();
    this.showTemplateSignBox(this.contract.getSignFrameAssignee());
  }

  //Check box ngày ký
  changedDateSign(event) {
    PDFSign.setShowDateSign(this.formSign.value.signDate);
  }

  //Check box ký bởi
  changedSignBy(event) {
    PDFSign.setShowSignBy(this.formSign.value.signBy);
  }

  //Nhập font size chữ ký
  inputFontSize(event) {
    PDFSign.setFontSize(event.target.value)
  }

  //Chọn hình thức hiển thị chữ ký
  selectStyleSignBox(e) {
    PDFSign.setStyleSelectedSignBox(Number(e.value));
  }

  //Them khung chu ky
  addSignBox() {
    // if ((!this.imgESign && this.selectedWayOfSign === "E_SIGN") || (!this.imgContent && this.selectedWayOfSign == "SIGN_SERVER")) {
    //   this.myAlert.showAlertOnlyNoti(localize.contract_alert_no_image_esign, "");
    // } else {
      PDFSign.addSignBox();
    // }
  }

  //Upload ảnh chữ ký
  handleFileImageInput(e) {
    this.imgFile = e.target.files && e.target.files.item(0);
    if (this.imgFile && this.imgFile !== null && this.imgFile !== undefined) {
      this.inputFileImageLabel = this.imgFile.name;

      if (!this.imgType.includes(this.imgFile.type)) {
        //Sai dinh dang
        this.formSign.controls.imageFile.setErrors({ type: true });
      } else if (this.imgFile.size > 2000000) {
        //Dung luong file lon
        this.formSign.controls.imageFile.setErrors({ size: true });
      } else {
        //Dam bao ca tieu chi

        //Chuyen file thanh base64
        this.getBase64String(this.imgFile, this.handleImgSignServer);
      }

    } else {
      this.formSign.controls.imageFile.setErrors({ required: true });
      this.imgBase64 = '';
      this.inputFileImageLabel = this.translate.instant('SmartCa_selectImage');
    }
  }

  //Chuyen Base64 sang blob
  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  //Chuyen file sang base64
  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //Handle base64 cua file
  handleImgSignServer(str, self) {
    self.imgBase64 = str;
    self.formSign.controls.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  //Set dữ liệu config mặc định
  setConfigSign(signConfig: InfoSignatureConfigObject) {
    console.log(signConfig);
    this.formSign.controls.signerName.setValue(signConfig.signerName || "");

    this.formSign.controls.visibleType.setValue(signConfig.visibleType || 5);
    PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) || 5);

    this.formSign.controls.base64Image.setValue(signConfig.base64Image || default_img);
    PDFSign.setImage(this.formSign.controls.base64Image.value || default_img);
    this.imgBase64 = this.img_prefix + (signConfig.base64Image || default_img);

    let imgTag: any = document.getElementById("img-smart-ca");
    if (imgTag) imgTag.src = this.imgBase64;

    this.formSign.controls.fontSize.setValue(signConfig.fontSize || "13");
    PDFSign.setFontSize(this.formSign.controls.fontSize.value || "13")

    this.formSign.controls.signBy.setValue(signConfig.signBy || false);
    PDFSign.setShowSignBy(this.formSign.controls.signBy.value || false);

    this.formSign.controls.signDate.setValue(signConfig.signDate || false);
    PDFSign.setShowDateSign(this.formSign.controls.signDate.value || false);

    console.log("Smart CA: ",this.formSign.value);
  }

  //Kiem tra session login smartCA
  getSessionSmartCA() {
    this.signatureService.getSessionSmartCA().subscribe((res: any) => {
      console.log(res);
      if (res.object?.oauth !== null) {
        this.oAuthCert = new SmartCAAuth(res.object?.oauth);
        this.smartCert = new SmartCACert(res.object?.certificate);
        this.getConfigSmartCA(this.smartCert.id);
        this.getCAInfo.emit( this.smartCert);
      } else {
        this.myAlert.showAlert(this.translate.instant('SmartCa_token_timeout'), "", true, "", this.translate.instant('SmartCa_loginSmart'), () => {
          const url = this.router.createUrlTree(['/app/console/signature-manager'], { queryParams: { tabName: "SMARTCA" } })
          window.open(url.toString(), '_blank')
        }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('modal_title_noti'));
      }
    }, err => {
      // this.myAlert.showErrorHandled(err);
      this.myAlert.showAlert(this.translate.instant('SmartCa_token_timeout'), "", true, "", this.translate.instant('btn_login'), () => {
        const url = this.router.createUrlTree(['/app/console/signature-manager'], { queryParams: { tabName: "SMARTCA" } })
        window.open(url.toString(), '_blank')
      }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('modal_title_noti'));
    })
  }

  /**
   * Lấy thông tin cấu hình chữ ký số SmartCA
   * @param id
   */
  getConfigSmartCA(id) {
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      this.configSmartCA = new InfoSignatureConfigResponse(res).object;
      this.setConfigSign(this.configSmartCA);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  signBySmartCA() {
    let options: Array<any> = PDFSign.getOptions();
    let listPosition: Array<any> = [];

    // if (!this.checkAllowSign()) {
    //   this.alert('Bạn không thể ký hợp đồng này!', 'error', false);
    //   return;
    // }

    if (options.length <= 0) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('SmartCa_not_enough_signature'), ConstantAlertType.ERROR);
      return;
    }

    for (var i = 0; i < options.length; i++) {
      let x = options[i].x;
      let y = options[i].y - options[i].h;
      let w = options[i].w + x;
      let h = options[i].h + y;
      let page = options[i].page;

      listPosition.push({
        page: page,
        rectangle: `${x},${y},${w},${h}`
      })
    }

    console.log("smart ca: ", this.formSign.value);
    let fileBase: any = document.getElementById("fileBase");
    let param =
    {
      "contractId": this.contractId,
      "signerName": this.controlAccount.userInfo.ten || this.authService.username || this.controlAccount.userInfo.sdt || "",
      "userId": this.authService.userId,
      "contract": fileBase.value,
      "listPosition": listPosition,
      "signerBy": this.formSign.controls.signBy.value,
      "signerDate": this.formSign.controls.signDate.value,
      "fontSize": Number(this.formSign.controls.fontSize.value),
      "visibleType": Number(this.formSign.controls.visibleType.value),
      "base64Image": this.formSign.controls.base64Image.value,
      "type": this.signTypeSmartCA || "HASH"
    }

    this.showLayoutConfirmSmartCA = true;
    this.confirmSmartCA = false;
    this.isWaitingConfirmSmartCA.emit(true);

    //post sign
    this.postSignSmartCA(param);
  }

  // checkConfirmedSmartCA() {
  //   this.postConfirmSignSmartCA(this.contractId);
  // }

  //Gui yeu cau ky smartCA
  postSignSmartCA(param) {
    this.errorService.disable();
    this.resetTimeoutSignSmartCA();
    this.reqSignSmartCA = this.signatureService.postSignSmartCA(param)
    this.reqSignSmartCA.subscribe((res: any) => {
      console.log(res);
      let fileBase64 = res['object']['fileBase64'];
      if (fileBase64) {
        let blob = this.converBase64toBlob(fileBase64, 'application/pdf');
        this.pdfSigned = blob;
        // PDFSign.preview(blob);
        // PDFSign.removeAllSignBox();
        this.savedSignature.emit(this.pdfSigned);
        this.showLayoutConfirmSmartCA = false;
        this.isWaitingConfirmSmartCA.emit(false);
        this.currentStepSign = 2;
        this.myAlert.showAlertOnlyNoti(this.translate.instant('SmartCa_save_signature_success'), 'success', '', this.translate.instant('btn_close'), () => {
        });
      }
      this.count = 0;
      this.errorService.enable();
    }, err => {
      if (this.count == 0) {
        this.msgError = this.translate.instant('SmartCa_transaction_cancel_on_app_smartCa');
      } else if (err.error && err.error.message && err.error.message == "ECT-00000035") {
        this.msgError = this.msgError;
      } else if (err.error && err.error.message && err.error.message == "ECT-00000042") {
        this.msgError = this.translate.instant('SmartCa_transaction_cancel_on_app_smartCa');
      } else {
        this.myAlert.showErrorHandled(err);
      }
      this.timeoutSmartCa = true;
      this.expiredSessionSmartCA.emit(true);
      this.count = 0;
      this.errorService.enable();
    })
  }

  // postConfirmSignSmartCA(contractId) {
  //   this.signatureService.postConfirmSignSmartCA(contractId).subscribe((res: any) => {
  //     console.log(res);
  //   }, err => {
  //     this.myAlert.showErrorHandled(err);
  //   })
  // }

  //Bo dem timeout request ky smartCA
  resetTimeoutSignSmartCA() {
    this.count = 120;
    this.timeoutSmartCa = false;
    this.expiredSessionSmartCA.emit(false);
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => {
        --this.count;
        if (this.count == 0 && this.reqSignSmartCA) { this.resetTimeoutSignSmartCA = null; this.timeoutSmartCa = true; this.expiredSessionSmartCA.emit(true) }
        return this.getTimer;
      })
    );
  }

  str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
  }

  get getTimer() {
    var minutes = Math.floor(this.count / 60);
    var seconds = this.count - minutes * 60;

    var finalTime = this.str_pad_left(minutes,'0',2) + ':' + this.str_pad_left(seconds,'0',2);

    return finalTime;
  }
}
