export class SmartCACert {
    id: string = '';
    status: string = '';
    certBase64: string = '';
    serialNumber: string = '';
    issuerDN: string = '';
    validTo: string = '';
    validFrom: string = '';
    subjectDN: string = '';
    accountEmail: string = '';

    constructor(obj = null) { 
        obj = obj || {};
        this.id = obj.id || '';
        this.status = obj.status || '';
        this.certBase64 = obj.certBase64 || '';
        this.serialNumber = obj.serialNumber || '';
        this.issuerDN = obj.issuerDN || '';
        this.validTo = obj.validTo || '';
        this.validFrom = obj.validFrom || '';
        this.subjectDN = obj.subjectDN || '';
        this.accountEmail = obj.accountEmail || '';
    }
}