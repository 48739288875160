export class SmartCAAuth {
    access_token: string = "";
    error: string = "";
    error_description: string = "";
    expires_in = 0;
    id: string = "";
    refresh_token: string = "";
    scope: string = "";
    token_type: string = "";
    username: string = "";

    constructor(d = null) {
        d = d || {};
        this.access_token = d.access_token || "";
        this.error = d.error || "";
        this.error_description = d.error_description || "";
        this.expires_in = d.expires_in;
        this.id = d.id || "";
        this.refresh_token = d.refresh_token || "";
        this.scope = d.scope || "";
        this.token_type = d.token_type || "";
        this.username = d.username || "";
    }
}