export class UtilsFunc {
    public static cloneArray(a: any[]) {
        let b: any[] = [];
        a.forEach((item) => {
            b.push(this.cloneObject(item));
        })

        return b;
    }

    public static cloneObject(a: any) {
        return JSON.parse(JSON.stringify(a));
    }

    public static addToDropList(data = [] ,param, key = "partyId") {
        key = key || "id";
        if (param === null) return data;
        let index = data.findIndex((item) => {
            return param[key] === item[key];
        });
        if (index >= 0) return data;
        return [...data, param]; 
    }

    public static getPropertiesSort(sortColConfig: any) {
        let arr = [];
        Object.keys(sortColConfig).forEach((item: string) => {
          let p = sortColConfig[item];
          let a = "";
          console.log(item);
          if (p == true) a = `${item}`;
          if (p == false) a = `-${item}`;
          if (p == null) a = "";
          if (p != null) {
            arr.push(a);
          }
        });
        return arr;
    }

    public static findLastIndex = (arr, fn) =>
        (arr
        .map((val, i) => [i, val])
        .filter(([i, val]) => fn(val, i, arr))
        .pop() || [-1])[0];


    //Convert hien thi sang du lieu luu tru
    public static convertCurency(value: any) {
        let curency: any
        value = value.replace(/,/g, '')
        curency = value.replace(/VNĐ/g, '')
        console.log(curency, '==============curency')
        curency = Math.abs(curency);
        return curency;
    }
    
    public static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * Chuyển đổi giây sang phút:giây
     * @param seconds 
     * @returns 
     */
    public static convertToMmss(seconds) {
        return Math.floor(seconds / 60) + ":" + (seconds % 60 ? seconds % 60 : '00');
    }

    /**
     * Kiểm tra string có phải json string hay hông
     * @param text 
     * @returns 
     */
    public static isValidJSON(text) {
        if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
            //the json is ok
            return true;
        }else{
            //the json is not ok
            return false;
        }
    }

    public static getCMND(str) {
        let split = str.split("UID=CMND:");
        if (split.length >= 2) {
            return split[1];
        } else {
            if (split[0] == "") {
                return str;
            } else {
                return split[0]
            }
        }
    }
}